import {
  KeyboardArrowDown,
  KeyboardArrowUp,
  Search,
} from "@mui/icons-material";
import { Button, Link, Menu, MenuItem, styled } from "@mui/material";
import { useState } from "react";
import { getDefaultDraftYear } from "../../utils/season";

export const SearchListNavbarMenu = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleMenu = (e: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(e.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <MenuButton
        size="small"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        color="inherit"
        sx={{
          display: { xs: "block", sm: "flex" },
        }}
        onClick={handleMenu}
      >
        <Search fontSize="small" />
        <div style={{marginLeft:3}}>Search</div>
        {anchorEl ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
      </MenuButton>
      <Menu
        id="menu-schools"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        sx={{
          flex: 1,
          marginTop: 1,
        }}
      >
        <MenuItem sx={{ width: 150 }}>
          <Link
            sx={{ textDecoration: "none" }}
            href={`/player/search?group=college&draftYear=${getDefaultDraftYear()}`}
          >
            College Players
          </Link>
        </MenuItem>
        <MenuItem>
          <Link
            sx={{ textDecoration: "none" }}
            href={`/player/search?group=pro`}
          >
            Pro Players
          </Link>
        </MenuItem>
      </Menu>
    </>
  );
};

const MenuButton = styled(Button)(({ theme }) => ({
  color: theme.palette.grey[300],
  fontSize: ".875rem",
}));
