import { getDefaultDraftYear } from "../../utils/season";

export type marvalMenu = {
  id: number;
  name: string;
  nav: string;
  disabled: boolean;
  subItems: [
    {
      id: number;
      name: string;
      nav: string;
      disabled: boolean;
    }
  ];
};

export const authenticatedMenu = [
  {
    id: 6,
    name: "Search",
    nav: "#",
    disabled: false,
    subItems: [
      {
        id: 6.1,
        name: "College",
        nav: "/player/search?group=college&draftYear=" + getDefaultDraftYear(),
        disabled: false,
      },
      {
        id: 6.2,
        name: "Pro",
        nav: "/player/search?group=pro",
        disabled: false,
      },
    ],
  },
];
