import {
  KeyboardArrowDown,
  KeyboardArrowUp,
  ManageAccounts,
} from "@mui/icons-material";
import { Button, Link, Menu, MenuItem, styled } from "@mui/material";
import { useState } from "react";
import { useGetUserQuery } from "../../graphql/generated/graphql";
import { hasPermission, PermissionEnum } from "../../utils/perms";

export const AdminNavbarMenu = () => {
  const user = useGetUserQuery();
  const canAssignScouts = hasPermission(user.data?.user?.role?.permissions, PermissionEnum.PermissionScoutAssignmentsWrite);
  const canModifyUsers = hasPermission(user.data?.user?.role?.permissions, PermissionEnum.PermissionUsersWrite);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleMenu = (e: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(e.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <MenuButton
        size="small"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        color="inherit"
        sx={{
          display: { xs: "block", sm: "flex" },
        }}
        onClick={handleMenu}
      >
        <ManageAccounts fontSize="small" />
        <div style={{marginLeft:3}}>Admin</div>
        {anchorEl ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
      </MenuButton>
      <Menu
        id="menu-schools"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        sx={{
          flex: 1,
          marginTop: 1,
        }}
      >
        {canAssignScouts &&
          <MenuItem sx={{ width: 200 }}>
            <Link
              sx={{ textDecoration: "none" }}
              href={`/admin/assignments`}
            >
              Scout Assignments
            </Link>
          </MenuItem>
        }
        <MenuItem>
          <Link
            sx={{ textDecoration: "none" }}
            href={`/admin/workouts/combine`}
          >
            Combine
          </Link>
        </MenuItem>
        {canModifyUsers &&
        <MenuItem>
          <Link
            sx={{ textDecoration: "none" }}
            href={`/admin/users`}
          >
            Users
          </Link>
        </MenuItem>
        }
      </Menu>
    </>
  );
};

const MenuButton = styled(Button)(({ theme }) => ({
  color: theme.palette.grey[300],
  fontSize: ".875rem",
}));
