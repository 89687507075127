"use client";

import { useState } from "react";
import Link from "next/link";
import {
  Typography,
  AppBar,
  Toolbar,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Box,
  Container,
  Fade,
  styled,
  Button,
} from "@mui/material";
import { SignOutButton } from "../SignOutButton";
import { SignInButton } from "./../SignInButton";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { UserInfo } from "../UserInfo";
import { AccountCircle } from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";
import React from "react";
import { NavBarMobileDrawer } from "./NavBarMobileDrawer";
import { ClubListNavbarMenu } from "@sumer/shared/components/clubs/ClubListNavbarMenu";
import { SchoolListNavbarMenu } from "../schools/SchoolListNavbarMenu";
import { SearchListNavbarMenu } from "../search/SearchListNavbarMenu";
import { AdminNavbarMenu } from "./AdminNavbarMenu";
import { useGetUserQuery } from "../../graphql/generated/graphql";
import { hasPermission, PermissionEnum } from "../../utils/perms";

const SEARCH_BAR_PADDING = 1;

export const NavBar = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  /* Disabling nav bar menu items for beta */
  // const [navAnchorEl, setNavAnchorEl] = useState<{
  //   [index: number]: HTMLElement;
  // } | null>(null);
  const [mobileDrawerOpen, setDrawerMobileOpen] = React.useState(false);

  const user = useGetUserQuery();
  const canSeeAdmin = hasPermission(user.data?.user?.role?.permissions, PermissionEnum.PermissionScoutAssignmentsWrite);

  const handleMenu = (e: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(e.currentTarget);
  };

  /* Disabling nav bar menu items for beta */
  // const handleNavMenu = (index: number, e: React.MouseEvent<HTMLElement>) => {
  //   setNavAnchorEl({ [index]: e.currentTarget });
  // };
  // const handleNavClose = () => {
  //   setNavAnchorEl(null);
  // };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleDrawerToggle = () => {
    setDrawerMobileOpen(!mobileDrawerOpen);
  };

  const handleDrawerOpen = (value: boolean) => {
    setDrawerMobileOpen(value);
  };

  return (
    <>
      <Fade in>
        <AppBar
          component="nav"
          position="fixed"
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <Container maxWidth="xl">
            <Toolbar
              variant="dense"
              disableGutters
              sx={{
                paddingTop: SEARCH_BAR_PADDING,
                paddingBottom: SEARCH_BAR_PADDING,
              }}
            >
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ display: { sm: "none" }, mr: 1 }}
              >
                <MenuIcon />
              </IconButton>
              <Typography variant="h5" component="div" mr={1}>
                <Link href="/">MARVAL</Link>
              </Typography>
              <AuthenticatedTemplate>
                <Stack
                  flex={1}
                  alignItems="center"
                  direction="row"
                  justifyContent={"space-around"}
                >
                  <Box
                    sx={{
                      flexGrow: 1,
                      marginLeft: 2,
                      display: { xs: "none", sm: "flex" },
                    }}
                  >
                    <ClubListNavbarMenu />
                    <SchoolListNavbarMenu />
                    <SearchListNavbarMenu />
                    <MenuButton>
                      <Link href="/film/search">Film</Link>
                    </MenuButton>
                    {canSeeAdmin && <AdminNavbarMenu />}
                  </Box>
                  {/* Disabling global search for beta */}
                  {/* <SearchInput /> */}
                  <IconButton
                    size="small"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleMenu}
                    color="inherit"
                    sx={{
                      marginLeft: 2,
                      display: { xs: "block", sm: "flex" },
                    }}
                  >
                    <AccountCircle />
                  </IconButton>
                  <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    <MenuItem onClick={handleClose} disabled>
                      <UserInfo />
                    </MenuItem>
                    <MenuItem onClick={handleClose}>
                      <SignOutButton />
                    </MenuItem>
                  </Menu>
                </Stack>
              </AuthenticatedTemplate>
              <UnauthenticatedTemplate>
                <div style={{ flex: 6 }} />
                <SignInButton />
              </UnauthenticatedTemplate>
            </Toolbar>
          </Container>
        </AppBar>
      </Fade>
      <NavBarMobileDrawer
        open={mobileDrawerOpen}
        handleDrawerOpen={handleDrawerOpen}
      />

      <Toolbar
        variant="dense"
        sx={{
          paddingTop: SEARCH_BAR_PADDING,
          paddingBottom: SEARCH_BAR_PADDING,
        }}
      />
    </>
  );
};

export const MenuButton = styled(Button)(({ theme }) => ({
  color: theme.palette.grey[300],
}));
