import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import {
  Box,
  Button,
  Link,
  Menu,
  MenuItem,
  Stack,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import { SchoolLogoImage } from "@sumer/shared/components/design/SchoolLogoImage";
import { useGetSchoolsByDivisionQuery } from "../../graphql/generated/graphql";
import _ from "lodash";
import React from "react";

export const SchoolListNavbarMenu = () => {
  const theme = useTheme();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const schoolQuery = useGetSchoolsByDivisionQuery({
    variables: { division: "1A" },
  });
  const schools = schoolQuery.data?.schools;

  const conferences = _.groupBy(schools, "conference");

  const handleMenu = (e: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(e.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <MenuButton onClick={handleMenu}>
        College
        {anchorEl ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
      </MenuButton>
      <Menu
        id="menu-schools"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        sx={{
          marginTop: 1,
        }}
      >
        {conferences &&
          Object.entries(conferences).map(([conference, schools]) => (
            <MenuItem key={conference}>
              <Stack direction="column" justifyContent="center">
                <Box>
                  <Typography color={theme.palette.grey[500]} fontSize={".8em"}>
                    {conference}
                  </Typography>
                </Box>
                <Box>
                  {schools.map((s) => (
                    <div
                      key={s.id}
                      style={{ display: "inline-block", padding: 0.3 }}
                    >
                      <School schoolId={s.id} schoolCode={s.schoolCode ?? ""} />
                    </div>
                  ))}
                </Box>
              </Stack>
            </MenuItem>
          ))}
      </Menu>
    </>
  );
};

const School = (props: { schoolId: string; schoolCode: string }) => {
  const theme = useTheme();
  const [isHovered, setIsHovered] = React.useState(false);

  return (
    <Box
      borderBottom={isHovered ? `3px solid ${theme.palette.grey[300]}` : "none"}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Link href={`/school/${props.schoolId}`}>
        <SchoolLogoImage schoolCode={props.schoolCode ?? ""} height={30} />
      </Link>
    </Box>
  );
};

const MenuButton = styled(Button)(({ theme }) => ({
  color: theme.palette.grey[300],
}));
