import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import {
  Box,
  Button,
  Link,
  Menu,
  MenuItem,
  Stack,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import {
  ClubInformation,
  clubInformation,
} from "@sumer/shared/utils/clubStaticData";
import { ClubLogoImage } from "../design/ClubLogoImage";
import _ from "lodash";
import React from "react";

export const ClubListNavbarMenu = () => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const clubs = _.orderBy(clubInformation, [(c) => c.division]);

  const divisions = _.groupBy(clubs, "division");

  const handleMenu = (e: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(e.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <MenuButton onClick={handleMenu}>
        Pro
        {anchorEl ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
      </MenuButton>
      <Menu
        id="menu-clubs"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        sx={{
          marginTop: 1,
        }}
      >
        {divisions &&
          Object.entries(divisions).map(([division, clubs]) => (
            <MenuItem key={division}>
              <Stack direction="column" justifyContent="center">
                <Box>
                  <Typography color={theme.palette.grey[500]} fontSize={".8em"}>
                    {division}
                  </Typography>
                </Box>
                <Box>
                  {clubs.map((c) => (
                    <div
                      key={c.clubId}
                      style={{
                        display: "inline-block",
                        padding: 0.3,
                      }}
                    >
                      <Club club={c} />
                    </div>
                  ))}
                </Box>
              </Stack>
            </MenuItem>
          ))}
      </Menu>
    </>
  );
};

const Club = (props: { club: ClubInformation }) => {
  const theme = useTheme();
  const [isHovered, setIsHovered] = React.useState(false);

  const { club } = props;
  return (
    <Box
      borderBottom={isHovered ? `3px solid ${theme.palette.grey[300]}` : "none"}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Link href={`/club/${club.clubId}`}>
        <ClubLogoImage clubCode={club.clubCode ?? ""} height={35} />
      </Link>
    </Box>
  );
};

const MenuButton = styled(Button)(({ theme }) => ({
  color: theme.palette.grey[300],
}));
